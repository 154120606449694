import axios from "axios";
import md5 from 'js-md5';

axios.defaults.baseURL = '/api';
const axios_t = async function (main, data = {}){
    data.sign = md5(main + 'KJENBAFUiaBNEIKFBNAIUKEFBNaikueNFKL')
    // 本地开发
    //return  await axios.post('/?s=' + main,data);
    // 部署
    return  await axios.post('/backend/public/?s=' + main,data);
}

export default {
    axios_t
}