import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/index',
            name: 'index',
            component: () => import('/pages/index.vue'),
            meta: {
                title: '查询Q绑-HeikeBook',
                metaTags: [
                    {
                        name: 'description',
                        content: 'HeikeBook在线专业查询Q绑集成工具，Q绑在线查询,在线查Q绑,在线查绑,Q绑查询,Q绑在线网页查询,16亿Q绑查询,Q绑8e数据库,16亿Q绑数据查询,2023Q绑在线查询,2021Q绑在线查询,Q绑在线查询官网,免费查询Q绑,手机号,社工库,社工'
                    },
                    {
                        name: 'keywords',
                        content: 'Q绑在线查询,在线查Q绑,在线查绑,Q绑查询,Q绑在线网页查询,16亿Q绑查询,Q绑8e数据库,16亿Q绑数据查询,2021Q绑在线查询,Q绑在线查询官网,免费查询Q绑,手机号,社工库,社工,英雄联盟,LOL,QQ密码,查QQ,2023Q绑在线查询,专业查询工具'
                    }
                ]
            }
        }
    ]
})
