import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
// bootstrap
import bootstrapcss from '@/assets/bootstrap/css/bootstrap.min.css'
import bootstrapjs from '@/assets/bootstrap/js/bootstrap.bundle'
import "bootstrap-icons/font/bootstrap-icons.css";
// element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// router
import router from '/router/index'
// echarts
import * as echarts from 'echarts'
// tools
import tools from "/js/tools";
// 动画 animate.css
import "animate.css";
// wow
//import "wowjs/css/libs/animate.css"// 巨坑... 此地方要引入两个css
//import wow from 'wowjs'

Vue.config.productionTip = false
// element
Vue.use(ElementUI)
// echarts
Vue.prototype.$echarts = echarts
// tools
Vue.prototype.$tools = tools
// wow
//Vue.prototype.$wow = wow

// 设置mate描述
let metaTags = [
  {
    name: 'description',
    content: 'HeikeBook在线专业查询Q绑集成工具，Q绑在线查询,在线查Q绑,在线查绑,Q绑查询,Q绑在线网页查询,16亿Q绑查询,Q绑8e数据库,16亿Q绑数据查询,2023Q绑在线查询,2021Q绑在线查询,Q绑在线查询官网,免费查询Q绑,手机号,社工库,社工'
  },
  {
    name: 'keywords',
    content: 'Q绑在线查询,在线查Q绑,在线查绑,Q绑查询,Q绑在线网页查询,16亿Q绑查询,Q绑8e数据库,16亿Q绑数据查询,2021Q绑在线查询,Q绑在线查询官网,免费查询Q绑,手机号,社工库,社工,英雄联盟,LOL,QQ密码,查QQ,2023Q绑在线查询,专业查询工具'
  }
];



if (metaTags){
  metaTags.forEach(tag => {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', tag.name);
    metaTag.setAttribute('content', tag.content);
    document.head.appendChild(metaTag);
  })
}

// 路由守卫
router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 代表从哪个路径跳转而来
  //next 是一个函数，表示放行
  // next() 放行  next('/login') 强制跳转
  // 不存在的路由直接强跳首页


  if (to.name === null){
    next('/index')
  }

  // 设置标题
  if (to.title === undefined){
    // 默认标题
    document.title = "查询Q绑-HeikeBook"
  }else {
    document.title = to.title
  }

  next()

})

new Vue({
  render: h => h(App),
  router,
  bootstrapcss,
  bootstrapjs,
}).$mount('#app')
